<div [ngSwitch]="widget.type">
	<mat-form-field
		appearance="outline"
		*ngSwitchCase="'select'"
		[style]="getStyleString()">
		<mat-label>
			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>
		</mat-label>
		<mat-select
			[(ngModel)]="getSelectWidget().selected"
			(ngModelChange)="widget.onChange($event)"
			[multiple]="getSelectWidget().multiple"
			[disabled]="!widget.enabled">
			<mat-option *ngIf="!getSelectWidget().multiple" [value]="null">
				{{ widget.noValueLabel }}
			</mat-option>
			<ng-container *ngFor="let opt of getSelectWidget().options">
				<mat-option *ngIf="opt.enabled" [value]="opt.value" [disabled]="!opt.enabled">
					{{ opt.key }}
				</mat-option>
			</ng-container>
		</mat-select>
	</mat-form-field>

	<div *ngSwitchCase="'radio'" class="widget widget-radio" [style]="getStyleString()">
		<label>
			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>
		</label>
		<mat-radio-group
			[disabled]="!widget.enabled"
			[(ngModel)]="getSelectWidget().value"
			(ngModelChange)="widget.onChange($event)">
			<mat-radio-button
				*ngIf="!widget.required"
				[name]="widget.id"
				[value]="null">
				{{ widget.noValueLabel }}
			</mat-radio-button>
			<mat-radio-button
				*ngFor="let opt of getSelectWidget().options"
				[name]="widget.id"
				[value]="opt.value">
				{{ opt.key }}
			</mat-radio-button>
		</mat-radio-group>
	</div>

	<mat-form-field appearance="outline" *ngSwitchCase="'search'" [style]="getStyleString()">
		<mat-label>
			{{ widget.label }}
		</mat-label>

		<input
			type="text"
			[placeholder]="widget.label"
			[formControl]="widget.formControl"
			[matAutocomplete]="auto"
			matInput>
		<button
			*ngIf="widget.value" matSuffix mat-icon-button aria-label="Clear"
			[disabled]="!widget.enabled"
			(click)="widget.setValue(null)">
			<mat-icon>close</mat-icon>
		</button>
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="widget.onChange($event)">
			<ng-container *ngFor="let opt of getSearchWidget().filteredOptions | async">
				<mat-option *ngIf="opt.enabled" [value]="opt.value" [disabled]="!opt.enabled">
					{{ opt.key }}
				</mat-option>
			</ng-container>
		</mat-autocomplete>
	</mat-form-field>

	<div *ngSwitchCase="'list'" class="widget widget-list" [style]="getStyleString()">
		<label>
			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>
		</label>
		<mat-accordion>
			<mat-expansion-panel
				*ngFor="let ref of widget.value; let i = index"
				(opened)="getListWidget().opened(ref)"
				(closed)="getListWidget().closed(ref)">
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{ i + 1 }}
					</mat-panel-title>
					<mat-panel-description>
						{{ getListWidgetDescription(ref) }}
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div>
<!--					<span>-->
<!--						DEBUG INFO: <br>-->
<!--						ref: {{ ref }}<br>-->
<!--						ModelNode: {{ getListWidget().getModelNodeByRef(ref) }}<br>-->
<!--						renderer: {{ getListWidget().getModelNodeByRef(ref)?.getRenderer() }}<br>-->
<!--						form: {{ getListWidget().getModelNodeByRef(ref)?.getGridForm() }}<br>-->
<!--					</span>-->
					<app-form-grid
						*ngIf="getListWidget().getModelNodeByRef(ref)?.getRenderer() === 'grid'"
						[node]="getListWidget().getModelNodeByRef(ref)"
						[form]="getListWidget().getModelNodeByRef(ref)?.getGridForm()"
						[isChild]="true"
						(created)="getListWidget().onFormCreated(ref, $event)">
					</app-form-grid>
					<app-form-simple
						*ngIf="getListWidget().getModelNodeByRef(ref)?.getRenderer() === 'simple'"
						[node]="getListWidget().getModelNodeByRef(ref)"
						(created)="getListWidget().onFormCreated(ref, $event)">
					</app-form-simple>
					<button
						*ngIf="getListWidget().canDelete()"
						mat-icon-button color="warn"
						class="float-right"
						(click)="getListWidget().delete(ref)">
						<mat-icon>delete_outline</mat-icon>
					</button>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
		<button
			*ngIf="getListWidget().canAdd()"
			mat-icon-button color="primary"
			(click)="getListWidget().new()">
			<mat-icon>playlist_add</mat-icon>
		</button>
		<br />
		<br />
	</div>

<!--	<div *ngSwitchCase="'gallery'" class="widget widget-gallery" [style]="getStyleString()">-->
<!--		<label>-->
<!--			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>-->
<!--		</label>-->
<!--&lt;!&ndash;		[(ngModel)]="getGalleryWidget().value"&ndash;&gt;-->
<!--&lt;!&ndash;		(ngModelChange)="widget.onChange($event)"&ndash;&gt;-->
<!--&lt;!&ndash;		[disabled]="!widget.enabled"&ndash;&gt;-->
<!--		<mat-grid-list-->
<!--			cols="4"-->
<!--			rowHeight="100px">-->
<!--&lt;!&ndash;			<mat-grid-tile *ngIf="!widget.required">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;					[name]="widget.id"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;					[value]="null"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;				<button mat-button>&ndash;&gt;-->
<!--&lt;!&ndash;					{{ widget.noValueLabel }}&ndash;&gt;-->
<!--&lt;!&ndash;				</button>&ndash;&gt;-->
<!--&lt;!&ndash;			</mat-grid-tile>&ndash;&gt;-->
<!--&lt;!&ndash;			<mat-grid-tile&ndash;&gt;-->
<!--&lt;!&ndash;				[style.background-image]="getGalleryWidget().getThumbnailURL(opt.key)"&ndash;&gt;-->
<!--&lt;!&ndash;				*ngFor="let opt of getGalleryWidget().options">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;					[name]="widget.id"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;					[value]="opt.value"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;				<span>{{ getGalleryWidget().getThumbnailURL(opt.key) | json }}</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;				<button mat-button>&ndash;&gt;-->
<!--&lt;!&ndash;					{{ opt.key }}&ndash;&gt;-->
<!--&lt;!&ndash;				</button>&ndash;&gt;-->
<!--&lt;!&ndash;			</mat-grid-tile>&ndash;&gt;-->
<!--		</mat-grid-list>-->
<!--	</div>-->

	<div *ngSwitchCase="'color'" class="widget widget-color" [style]="getStyleString()">
		<input
			[disabled]="!widget.enabled"
			[(colorPicker)]="widget.value"
			[value]="widget.value"
			(colorPickerChange)="widget.onChange($event)" />
		<span [(colorPicker)]="widget.value" [style.background]="widget.value"></span>
		<br style="clear: both;" />
	</div>

	<div *ngSwitchCase="'bool'" class="widget widget-bool" [style]="getStyleString()">
		<mat-checkbox
			[disabled]="!widget.enabled"
			[(ngModel)]="widget.value"
			(ngModelChange)="widget.onChange($event)">
			{{ widget.label }}
		</mat-checkbox>
	</div>

	<mat-form-field appearance="outline" *ngSwitchCase="'number'" [style]="getStyleString()">
		<mat-label>
			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>
		</mat-label>
		<input
			matInput
			[disabled]="!widget.enabled"
			[(ngModel)]="widget.value"
			[type]="getInputWidget().inputType"
			[min]="getNumberWidget().minValue"
			[max]="getNumberWidget().maxValue"
			[step]="getNumberWidget().step"
			[placeholder]="widget.label"
			(blur)="widget.onChange($event)"
			(ngModelChange)="widget.onChangeDebounced($event)" />
	</mat-form-field>

	<div *ngSwitchCase="'date'" class="widget widget-date">
		<input
			[(ngModel)]="widget.value"
			[disabled]="!widget.enabled"
			[type]="getInputWidget().inputType"
			[placeholder]="widget.label"
			(ngModelChange)="widget.onChange($event)" />
	</div>

	<mat-form-field appearance="outline" *ngSwitchCase="'text'" [style]="getStyleString()">
		<mat-label>
			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>
		</mat-label>
		<textarea
			matInput
			rows="4"
			[placeholder]="widget.label"
			[disabled]="!widget.enabled"
			[(ngModel)]="widget.value"
			[type]="getInputWidget().inputType"
			(blur)="widget.onChange($event)"
			(ngModelChange)="widget.onChangeDebounced($event)">
		</textarea>
	</mat-form-field>

	<mat-form-field appearance="outline" *ngSwitchCase="'input'" [style]="getStyleString()">
		<mat-label>
			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>
		</mat-label>
		<input
			matInput
			[placeholder]="widget.label"
			[disabled]="!widget.enabled"
			[(ngModel)]="widget.value"
			[type]="getInputWidget().inputType"
			(blur)="widget.onChange($event)"
			(ngModelChange)="widget.onChangeDebounced($event)" />
	</mat-form-field>

	<mat-form-field appearance="outline" *ngSwitchCase="'readonly'" [style]="getStyleString()">
		<mat-label>
			{{ widget.label }}
		</mat-label>
		<input
			matInput
			[placeholder]="widget.label"
			[disabled]="true"
			[(ngModel)]="widget.value"
			[type]="getInputWidget().inputType" />
	</mat-form-field>
</div>
