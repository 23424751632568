import { Field } from '../domain/Field';
import { NumericType } from '../types/Numeric';
import { SelectType } from '../types/SelectType';
import { BoolWidget } from './widget.bool';
import { ColorWidget } from './widget.color';
import { DateWidget } from './widget.date';
import { InputWidget } from './widget.input';
import { NumberWidget } from './widget.number';
import {SelectWidget, RadioWidget, SearchWidget, GalleryWidget} from './widget.select';
import { TextWidget } from './widget.text';
import {ReadOnlyWidget} from "./widget.readonly";
import {ListWidget} from "./widget.list";
import {ListType} from "../types/ListType";

export class WidgetFactory {

	static createChoice(field: Field): SelectWidget {
		const type: SelectType = field.getType() as SelectType;

		if (type.isRadio()) {
			return new RadioWidget({
				value: null,
				name: field.getName(),
				label: field.getLabel(),
				required: type.required,
				options: type.getOptions().map((item: string) => {
					return { key: item, value: item, enabled: true };
				}),
			});
		}
		else if (type.isSearch()) {
			return new SearchWidget({
				value: null,
				name: field.getName(),
				label: field.getLabel(),
				required: type.required,
				options: type.getOptions().map((item: string) => {
					return { key: item, value: item, enabled: true };
				}),
			});
		}
		else if (type.isGallery()) {
			return new GalleryWidget({
				value: null,
				name: field.getName(),
				label: field.getLabel(),
				required: type.required,
				options: type.getOptions().map((item: string) => {
					return { key: item, value: item, enabled: true };
				}),
				thumbnails: type.getThumbnails(),
			});
		}
		return new SelectWidget({
			value: null,
			name: field.getName(),
			label: field.getLabel(),
			required: type.required,
			multiple: type.isMultiSelect(),
			options: type.getOptions().map((item: string) => {
				return { key: item, value: item, enabled: true };
			}),
		});
	}

	static createBool(field: Field) {
		return new BoolWidget({
			value: false,
			name: field.getName(),
			label: field.getLabel(),
			required: field.getType().required,
		});
	}

	static createNumeric(field: Field) {
		const type: NumericType = field.getType() as NumericType;
		return new NumberWidget({
			value: null,
			name: field.getName(),
			label: field.getLabel(),
			required: type.required,
			minValue: type.getMin(),
			maxValue: type.getMax(),
			step: type.getStep(),
		});
	}

	static createColor(field: Field) {
		return new ColorWidget({
			value: '',
			name: field.getName(),
			label: field.getLabel(),
			required: field.getType().required,
		});
	}

	static createDate(field: Field) {
		return new DateWidget({
			value: null,
			name: field.getName(),
			label: field.getLabel(),
			required: field.getType().required,
		});
	}

	static createTextMultiline(field: Field) {
		return new TextWidget({
			value: null,
			name: field.getName(),
			label: field.getLabel(),
			required: field.getType().required,
		});
	}

	static createText(field: Field) {
		return new InputWidget({
			value: null,
			name: field.getName(),
			label: field.getLabel(),
			required: field.getType().required,
		});
	}

	static createReadOnly(field: Field) {
		return new ReadOnlyWidget({
			value: null,
			name: field.getName(),
			label: field.getLabel(),
			required: field.getType().required,
		});
	}

	static createList(field: Field) {
		const type: ListType = field.getType() as ListType;

		return new ListWidget({
			value: null,
			name: field.getName(),
			label: field.getLabel(),
			required: field.getType().required,
			field,
			types: type.getTypes(),
		});
	}
}
